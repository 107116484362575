export default function App() {
  return (
    <>
      {window.location.replace(
        (getRandomInt(2) == 0 ? "https://gamma.io" : "https://tradeport.xyz") +
          window.location.pathname +
          "?ref=agrgtr.xyz"
      )}
    </>
  );
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
